import React from "react"
import {UserProfile} from "./UserProfile";

export const ExternalLinks = () => {
    return (
        <div className="row my-1">
            <div className="col">
                <div id="nav" className="text-right">
                    <div className="row justify-content-end mx-2">
                        <div className="col-auto px-2 py-1">
                            <a href="https://train.csie.ntu.edu.tw/train/courses.php?q=%E6%9D%8E%E6%A0%B9%E9%80%B8"
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{letterSpacing: "1px", color: "black"}}>
                                <img src="/images/courses.png"
                                     alt="courses"
                                     height="26"
                                     style={{verticalAlign: "-30%"}}/> 招生中課程
                            </a>
                        </div>
                        <div className="col-auto px-2 py-1">
                            <a href="/fb/"
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{letterSpacing: "1px", color: "black"}}>
                                <img src="/images/fb.png"
                                     alt="fb"
                                     height="26"
                                     style={{verticalAlign: "-30%"}}/> Facebook
                            </a>
                        </div>
                        <div className="col-auto px-2 py-1">
                            <a href="/yt/"
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{letterSpacing: "1px", color: "black"}}>
                                <img src="/images/yt.png"
                                     alt="yt"
                                     height="26"
                                     style={{verticalAlign: "-30%"}}/> YouTube
                            </a>
                        </div>
                        <div className="col-auto px-2 py-1">
                            <a href="/medium/"
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{letterSpacing: "1px", color: "black"}}>
                                <img src="/images/md.png"
                                     alt="md"
                                     height="26"
                                     style={{verticalAlign: "-30%"}}/> Medium
                            </a>
                        </div>
                        <div className="col-auto px-2 py-1">
                            <a href="/discord/"
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{letterSpacing: "1px", color: "black"}}>
                                <img src="/images/discord.png"
                                     alt="discord"
                                     height="26"
                                     style={{verticalAlign: "-30%"}}/> Discord
                            </a>
                        </div>
                        <div className="col-auto px-2 py-1">
                            <a href="mailto:feis.studio@gmail.com"
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{letterSpacing: "1px", color: "black"}}>
                                <img src="/images/mail.png"
                                     alt="mail"
                                     height="26"
                                     style={{verticalAlign: "-30%"}}
                                     className="mr-2" /> Email
                            </a>
                        </div>
                        <div className="col-auto px-2 py-1">
                            <UserProfile/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
import React from 'react';
import * as serviceWorker from './serviceWorker'
import App from "./App";
import Firebase, {FirebaseContext} from "./Firebase";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!); 
root.render(
    <FirebaseContext.Provider value={new Firebase()}><App /></FirebaseContext.Provider>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {CourseListEntry, FirebaseContext} from "./Firebase";
import {User} from 'firebase/auth'
import Home from './Home'
import Course from "./Course"
import {AdministrationPage} from "./AdministrationPage"

export const AppContext = React.createContext<{
    user: User | null
    courses: CourseListEntry[] | null
}>({user: null, courses: null})


function ExternalRedirect(props: any) {
    useEffect(() => {
        window.location.href = props.to
      }, [props.to]);
    
      return null; // 這個組件不需要渲染任何內容
}

const App = () => {
    const [user, setUser] = useState<User|null>(null)
    const [courses, setCourses]  = useState<CourseListEntry[]|null>(null)
    const firebase = useContext(FirebaseContext)

    useEffect(
        () => {
            if (firebase == null) return;
            firebase.auth.onAuthStateChanged((user: any) => {
                setUser(user)
                if (user != null && user.uid != null) {
                    firebase.getCourses().then(courses => {
                        setCourses(courses)
                    }).catch(e => {
                        console.log(e)
                    })
                } else {
                    setCourses(null)
                }
            })
        },
        [firebase]
    )

    return <AppContext.Provider value={{user, courses}}>
        <BrowserRouter>
            <Routes>
                <Route path="/vr"
                       element={
                            <ExternalRedirect to="https://hackmd.io/@feislee/SkoCBFjgi" />
                       }/>
                <Route path="/fb"
                       element={
                            <ExternalRedirect to="https://www.facebook.com/feisstudio" />
                       }/>
                <Route path="/courses"
                       element={
                            <ExternalRedirect to="https://train.csie.ntu.edu.tw/train/courses.php?q=%E6%9D%8E%E6%A0%B9%E9%80%B8" />
                       }/>
                <Route path="/yt"
                       element={
                            <ExternalRedirect to="https://www.youtube.com/c/KenYiLee" />
                       }/>
                <Route path="/support"
                       element={
                            <ExternalRedirect to="https://p.ecpay.com.tw/CD9CD" />
                       }/>
                <Route path="/medium"
                        element = {
                            <ExternalRedirect to="https://medium.com/feis-studio" />
                        }/>
                <Route path="/discord"
                        element= {
                            <ExternalRedirect to="https://discordapp.com/invite/FAXhhTt" />
                        }/>
                <Route path="/AdministrationPage"
                    element={
                        <AdministrationPage/>
                    }/>
                <Route path="/"
                    element={
                        <Home/>        
                    }/>
                <Route path="/:token"
                    element={
                        <Course/>
                    }/>
                <Route path="/:token/:anchor"
                    element={
                        <Course/>
                    }/>         
            </Routes>
        </BrowserRouter>
    </AppContext.Provider>
}

export default App
import React from "react";
import firebase from "firebase/compat/app";
// Required for side-effects
import "firebase/compat/firestore";
import "firebase/compat/auth";

export const FirebaseContext = React.createContext<Firebase|null>(null)

const config = {
    apiKey: "AIzaSyAlLfcLTuVaxkr37fbonsSxShq2pkDepVI",
    authDomain: "auth.feis.studio",
    databaseURL: "https://courses-cb552.firebaseio.com",
    projectId: "courses-cb552",
    storageBucket: "courses-cb552.appspot.com",
    messagingSenderId: "979555621990",
    appId: "1:979555621990:web:b2cd871c2631845c1261a7",
    measurementId: "G-XGK9M1E7CY"
}

export interface CourseListEntry {
    issue?: number
    id: string
    name?: string
    order?: number
}

export default class Firebase {

    public auth: firebase.auth.Auth
    public firestore : firebase.firestore.Firestore

    constructor() {
        firebase.initializeApp(config)
        this.auth = firebase.auth()
        this.firestore = firebase.firestore()
    }

    async signIn() {
        return this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
    }

    async logout(): Promise<void> {
        return this.auth.signOut()
    }

    async register(courseId: string, username: string) {
        const user = this.auth.currentUser

        if (user == null || user.uid == null) return

        await this
            .firestore
            .collection("registration")
            .doc(courseId)
            .collection("users")
            .doc(user!.email!)
            .set({
                name: username
            })
            .catch((e: any) => {
                console.log(e)
            })
    }

    async getCourses() : Promise<CourseListEntry[]|null> {
        const user = this.auth.currentUser
        if (user == null || user.uid == null) {
            console.log("User not found")
            return null
        }

        const docs = await this
            .firestore
            .collection('users')
            .doc(user.email!)
            .collection('courses')
            .get()
            .then((result: any) => {
                return result.docs.map((doc: { data: () => CourseListEntry; id: any; }) => {
                    return {...doc.data(), id: doc.id} as CourseListEntry
                })
            })
            .catch((e: any) => {
                console.log(e)
                return null
            })

        if (docs != null) {
            docs.sort((a: any, b: any) => {
                if (a.order != null && b.order != null) {
                    return a.order - b.order
                }
                if (a.order == null) {
                    return 1
                }
                if (b.order == null) {
                    return -1
                }
                if (a.issue != null && b.issue != null) {
                    if (a.issue !== b.issue) {
                        return a.issue - b.issue
                    }
                }
                if (a.name == null || b.name == null) {
                    return 0
                }
                return a.name.localeCompare(b.name)
            })
        }
        return docs
    }

    async getRegistrations() : Promise<any[]> {
        return await this
            .firestore
            .collection('registration')
            .get()
            .then(async (result: any) => {
                let courses : any[] = []
                await Promise.all(
                    result.docs.map(async (doc: any) => {
                        let course = {...doc.data(), id: doc.id, users: []}
                        await this.firestore
                            .collection('registration')
                            .doc(doc.id)
                            .collection('users')
                            .get()
                            .then((userSnapshot: any) => {
                                userSnapshot.docs.forEach((user: any) => {
                                    course.users.push({
                                        ...user.data(),
                                        email: user.id,
                                    })
                                })
                            })
                        courses.push(course)
                    })
                )
                return courses
            })
            .catch((e:any) => {
                console.log(e)
                return []
            })
    }

    async confirmRegistration(
        courseId: string,
        courseName: string,
        courseOrder: number,
        courseIssue: number,
        userEmail: string,
        username: string,
        userStudentId: string) {

        await this.firestore
            .collection('users')
            .doc(userEmail)
            .collection('courses')
            .doc(courseId)
            .set({
                name: courseName,
                order: courseOrder,
                issue: courseIssue,
            })
            .catch((e: any) => {
                console.log(e)
            })

        await this.firestore
            .collection('courses')
            .doc(courseId)
            .collection('users')
            .doc(userEmail)
            .set({
                name: username,
                studentId: userStudentId,
            })
            .catch((e: any) => {
                console.log(e)
            })

        await this.deleteRegistration(courseId, userEmail)
    }

    async deleteRegistration(courseId: string, userEmail: string) {
        console.log(courseId, userEmail)
        await this.firestore
            .collection('registration')
            .doc(courseId   )
            .collection('users')
            .doc(userEmail)
            .delete()
            .catch((e : any) => {
                console.log(e)
            })
    }
}

import {IRouteData} from "./IRouteData"
import firebase from "firebase/compat/app";
// Required for side-effects
import "firebase/firestore";

class Database {
    async get(token: string) {
        if (token === null) return null

        return await firebase.apps[0]
            .firestore()
            .collection("courses")
            .doc(token)
            .get()
            .then(
                (doc: any) => {
                    if (doc === undefined || doc.data() === undefined) {
                        return null
                    }
                    return JSON.parse(doc.data()!.data) as IRouteData
                })
            .catch(() => {
                return null
            })
    }
}



const db = new Database()

export default db;

import {Dropdown, DropdownButton} from "react-bootstrap";
import React from "react";
import {CourseListEntry} from "./Firebase";

interface MyCoursesProps {
    courses: CourseListEntry[] | null
}

export function MyCourses(props: MyCoursesProps) {
    if (props.courses == null || props.courses.length === 0)
        return <></>

    return (
        <DropdownButton title="選擇我的課程" variant="outlined-secondary" id="courses">
            {
                props.courses.map(course =>
                    <Dropdown.Item key={course.id} href={course.id}>{course.name}</Dropdown.Item>
                )
            }
        </DropdownButton>
    )
}
import React, {useContext, useEffect, useState} from "react"
import {FirebaseContext} from "./Firebase"
import {UserProfile} from "./UserProfile"
import {AppContext} from "./App"
import {Button} from "react-bootstrap"

export function AdministrationPage() {
    const firebase = useContext(FirebaseContext)
    const {user} = useContext(AppContext)
    const [courses, setCourses] = useState([])

    useEffect(() => {
        if (user != null && firebase != null) {
            firebase.getRegistrations().then((courses: any) => {
                setCourses(courses)
            })
        }
    }, [user, firebase])
    return <>
        <UserProfile/>
        {
            courses.map((course: any) => {
                return (
                    <div key={course.id} className={"py-3"}>
                        {course.name}
                        <ul>
                            {
                                course.users.map((user: any) => {
                                    return (
                                        <li key={course.id + "-" + user.email} className={"py-2"}>
                                            <Button
                                                className={"mx-2"}
                                                onClick={() => {
                                                    const comment =
                                                        prompt("輸入學號 (" + user.name + ")")
                                                    if (comment !== null && firebase != null) {
                                                        firebase.confirmRegistration(
                                                            course.id,
                                                            course.name,
                                                            course.order,
                                                            course.issue,
                                                            user.email,
                                                            user.name,
                                                            comment).then(()=>{ window.location.reload() })
                                                    }
                                                }}
                                            >確認</Button>
                                            <Button
                                                className={"mx-2"}
                                                onClick={() => {
                                                    if (window.confirm("確認要刪除 " + user.name + " 的申請？")) {
                                                        firebase!.deleteRegistration(course.id, user.email)
                                                            .then(() => {window.location.reload()})
                                                    }
                                            }}>刪除</Button>
                                            <strong className={"mx-2"}>{user.name}</strong>
                                            <span>({user.email})</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                )
            })
        }
    </>
}
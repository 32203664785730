import React, {useContext, useState} from "react"
import {Button, ButtonToolbar, Form, Modal} from "react-bootstrap"
import {FirebaseContext} from "./Firebase";
import {AppContext} from "./App";
import {MyCourses} from "./MyCourses";
import CoursesInfo from "./CoursesInfo";

export const UserProfile = () => {
    const firebase = useContext(FirebaseContext)!
    const {user, courses} = useContext(AppContext)
    const [show, setShow] = useState(false)
    const [validated, setValidated] = useState(false)

    if (user == null) {
        return (
            <>
                <FirebaseContext.Consumer>
                    {
                        (firebase) => {
                            return <Button
                                variant="link"
                                onClick={async () => {
                                    return firebase!
                                        .signIn()
                                }}
                                style={{
                                    verticalAlign: "middle",
                                    padding: 0,
                                    margin: 0,
                                    letterSpacing: "1px",
                                    color: "black",
                                }}>
                                <img src="/images/user.png"
                                     alt="user"
                                     height="26"
                                     style={{
                                         verticalAlign: "-30%",
                                         paddingLeft: '5px',
                                         paddingRight: '5px',
                                     }}/> 登入
                            </Button>
                        }
                    }
                </FirebaseContext.Consumer>
            </>
        )
    }

    return (
        <ButtonToolbar>
            <MyCourses courses={courses}/>
            <Button
                variant="link"
                onClick={async () => firebase!.logout()}
                style={{
                    verticalAlign: "middle",
                    padding: 0,
                    margin: 0,
                    letterSpacing: "1px",
                    color: "black",
                }}>
                <img src="/images/user.png"
                     alt="user"
                     height="26"
                     style={{
                         verticalAlign: "-30%",
                         paddingLeft: '5px',
                         paddingRight: '5px',
                     }}/> 登出
            </Button>
            <div style={{
                paddingLeft: "10px",
                lineHeight: "38px",
            }}>{user.displayName}</div>
            <Modal show={show} onHide={() => setShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>課程註冊</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} name="test" onSubmit={
                        (e: any) => {
                            const form = e.currentTarget;
                            if (form.checkValidity() === false) {
                                setValidated(true)
                            } else {
                                const course = e.currentTarget.courseName.value
                                const username = e.currentTarget.username.value
                                firebase.register(
                                    course,
                                    username)
                                    .then(() => {
                                        setValidated(false)
                                        setShow(false)
                                    })
                            }

                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }>
                        <Form.Group controlId="courseName">
                            <Form.Label>註冊課程</Form.Label>
                            <Form.Control as="select" required>
                                <option value={""}>請選擇您上的課程</option>
                                {
                                    CoursesInfo.Registration.map(e =>
                                    {
                                        return <option value={e.token}>{e.name}</option>
                                    })
                                }
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                請選取您報名的課程
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="username">
                            <Form.Label>您的姓名</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="輸入您的名字"
                                required
                                />
                            <Form.Control.Feedback type="invalid">
                                為了比對報名資料，請填入您的姓名
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                        >送出
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </ButtonToolbar>
    )
}
// noinspection SpellCheckingInspection
import gp from './db/gp.json'
import c from './db/youtube/cb_youtube.json'
import leetcode30 from './db/youtube/leetcode30.json'
import leetcode_may from './db/youtube/leetcode_may.json'
import leetcode_june from './db/youtube/leetcode_june.json'
import leetcode_july from './db/youtube/leetcode_july.json'
import leetcode_top150 from './db/youtube/leetcode_top150.json'
import python_chat from './db/youtube/python_chat.json'
import c_keywords from './db/youtube/c_keywords.json'
import {IRouteData} from './IRouteData'

// noinspection SpellCheckingInspection
const rawDb : {[key: string]: IRouteData} = {}

rawDb[gp.token] = gp
rawDb[c.token] = c
rawDb[leetcode30.token] = leetcode30
rawDb[leetcode_may.token] = leetcode_may
rawDb[leetcode_june.token] = leetcode_june
rawDb[leetcode_july.token] = leetcode_july
rawDb[leetcode_top150.token] = leetcode_top150
rawDb[c_keywords.token] = c_keywords
rawDb[python_chat.token] = python_chat

class Database {
    async get(token: string) {
        if (token in rawDb) {
            return rawDb[token]
        }
        return null
    }
}

const db = new Database()

export default db;
